import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook, faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { Link } from "gatsby"
import { Container, Row, Col, ListGroup, Button } from "react-bootstrap"

export default ({ children }) => (
  <footer className="pt-4 bg-dark text-light">
    <Container>
      <Row>
        <Col xs="12" sm="4">
          <h5 className="text-white">Contact</h5>
          <Button block className="mb-3" href="tel://12058332589">
            Call now
          </Button>
          <Button
            block
            className="mb-3"
            href="https://www.google.com/maps/dir/Russo%20White%20&%20Keller%20P.C."
          >
            Get Directions
          </Button>
          <a href="tel://12058332589" className="text-light">
            (205) 833-2589
          </a>
        </Col>

        <Col xs="12" sm={{ span: 3, offset: 1 }} className="mt-5 mt-sm-0">
          <h5 className="text-white mt-3 mt-sm-0">Birmingham Office</h5>
          <address className="text-center text-sm-left">
            315 Gadsden Highway
            <br />
            Suite D<br />
            Birmingham, AL 35235
          </address>
        </Col>
        <Col xs="12" sm={{ span: 3, offset: 1 }} className="mt-5 mt-sm-0">
          <h5 className="text-white mt-3 mt-sm-0">Pell City Office</h5>
          <address className="text-center text-sm-left">
            1917 Cogswell Avenue
            <br />
            Pell City, AL 35125
          </address>
        </Col>

        <Col xs="12" sm="4" className="mt-5">
          <h5 className="text-white">Practice Areas</h5>
          <ListGroup variant="flush" className="text-center">
            <ListGroup.Item className="bg-transparent">
              <Link className="text-muted" to="/practice-areas/bankruptcy">
                Bankruptcy
              </Link>
            </ListGroup.Item>
            <ListGroup.Item className="bg-transparent">
              <Link
                className="text-muted"
                to="/practice-areas/criminal-defense"
              >
                Criminal Defense
              </Link>
            </ListGroup.Item>
            <ListGroup.Item className="bg-transparent">
              <Link className="text-muted" to="/practice-areas/family-law">
                Family Law
              </Link>
            </ListGroup.Item>
            <ListGroup.Item className="bg-transparent">
              <Link className="text-muted" to="/practice-areas/personal-injury">
                Personal Injury
              </Link>
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col xs="12" sm="4" className="mt-5">
          <h5 className="text-white">Resources</h5>
          <ListGroup variant="flush" className="text-center">
            <ListGroup.Item className="bg-transparent">
              <a
                className="text-muted"
                href="https://cdn.rwkattorneys.com/Divorce-Intake.pdf"
              >
                Divorce Intake Form
              </a>
            </ListGroup.Item>
            <ListGroup.Item className="bg-transparent">
              <a
                className="text-muted"
                href="https://cdn.rwkattorneys.com/Adoption-Intake-Form.pdf"
              >
                Adoption Intake Form
              </a>
            </ListGroup.Item>
            <ListGroup.Item className="bg-transparent">
              <a
                className="text-muted"
                href="https://cdn.rwkattorneys.com/Bankruptcy-Intake-Form.pdf"
              >
                Bankruptcy Intake Form
              </a>
            </ListGroup.Item>
            <ListGroup.Item className="bg-transparent">
              <a
                className="text-muted"
                href="https://cdn.rwkattorneys.com/Bankruptcy-Disclosures.pdf"
              >
                Bankruptcy Disclosures
              </a>
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col xs="12" sm="4" className="mt-5">
          <h5 className="text-white">About</h5>
          <ListGroup variant="flush" className="text-center">
            <ListGroup.Item className="bg-transparent">
              <Link className="text-muted" to="/about">
                Mission &amp; Values
              </Link>
            </ListGroup.Item>
            <ListGroup.Item className="bg-transparent">
              <Link className="text-muted" to="/attorneys">
                Attorneys
              </Link>
            </ListGroup.Item>
            <ListGroup.Item className="bg-transparent">
              <Link className="text-muted" to="/about/legal-disclaimer">
                Legal Disclaimer
              </Link>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
    </Container>
  </footer>
)
