import React from "react"
import "./layout.scss"
import Header from "./header"
import Footer from "./footer"
import { Helmet } from "react-helmet"
import favicon from "../favicons/favicon.ico"
import { dom, config } from "@fortawesome/fontawesome-svg-core"

// Disable the automatic insertion
// of CSS into the head of the document.
config.autoAddCss = false

export default ({ children }) => (
  <div>
    <Helmet
      titleTemplate="%s - RWK Attorneys"
      defaultTitle="Affordable Legal Representation"
    >
      <link rel="icon" href={favicon} />
      <style type="text/css">{dom.css()}</style>
    </Helmet>

    <Header />

    {children}

    <Footer />
  </div>
)
