import React from "react"
import Helmet from "react-helmet"
import Layout from "../../../components/layout"
import { Link } from "gatsby"
import PageHeader from "../../../components/page-header"
import { Row, Col, Container } from "react-bootstrap"
import ContactForm from "../../../components/contact-us-form"

export default () => (
	<Layout style={{ color: `teal` }}>
		<Helmet title="Child Support Negotiation Lawyers | RWK Attorneys" defer={false} />

		<PageHeader>
			<Row>
				<Col>
					<nav aria-label="breadcrumb">
						<ol class="breadcrumb">
							<li class="breadcrumb-item"><Link to="/">Home</Link></li>
							<li class="breadcrumb-item"><Link to="/practice-areas">Practice Areas</Link></li>
							<li class="breadcrumb-item"><Link to="/practice-areas/bankruptcy">Bankruptcy</Link></li>
							<li class="breadcrumb-item active" aria-current="page">Child Support</li>
						</ol>
					</nav>
				</Col>
			</Row>

		</PageHeader>

		<Container>
			<Row>
				<Col md="3">
					<ContactForm />
				</Col>
				<Col>
					<h1>Child Support Negotiation</h1>

					<p>If you are facing a situation in which you are behind in child support payments, we can also assist you in those proceedings.  By evaluating your income and that of the other parent, we may be able to lower the child support payments and set you up on a payment plan to pay the past due amount as well.  Additionally, a chapter 13 bankruptcy will allow you to pay back the past due child support (child support arrearage) over a period of up to 5 years.</p>

					<p>If you would like for an attorney to calculate child support for you or need to speak with an attorney concerning child support, please call us at (205) 833-2589 or contact us immediately for a free consultation!</p>
				</Col>
			</Row>
		</Container>
	</Layout>
)