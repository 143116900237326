import React from "react"
import "./header.scss"
import { Link } from "gatsby"

import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faReceipt,
  faPhoneAlt,
  faDirections,
  faInfoCircle,
  faUserTie,
  faGavel,
  faHome,
} from "@fortawesome/free-solid-svg-icons"

export default () => (
  <>
    <Navbar
      collapseOnSelect
      expand="sm"
      bg="dark"
      variant="dark"
      className="py-4"
    >
      <Container>
        <Navbar.Brand href="/" className="ml-auto">
          Russo, White &amp; Keller
          <span className="d-none d-sm-inline-block">, P.C.</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="text-right text-sm-left ml-auto">
            <Nav.Link className="d-sm-none my-2" as={Link} to="/">
              <FontAwesomeIcon icon={faHome} /> Home
            </Nav.Link>
            {/* <Nav.Link className="my-2" as={Link} to="/contact">
              <FontAwesomeIcon icon={faReceipt} className="fa-lg" /> Get Quote
            </Nav.Link> */}
            <Nav.Link className="my-2" href="tel://12058332589">
              <FontAwesomeIcon icon={faPhoneAlt} className="fa-lg" /> Call Now
            </Nav.Link>
            <Nav.Link
              className="my-2"
              href="https://www.google.com/maps/dir/Russo%20White%20&%20Keller%20P.C."
              target="_blank"
            >
              <FontAwesomeIcon icon={faDirections} /> Get Directions
            </Nav.Link>
            <section className="d-sm-none">
              <Nav.Link
                className="my-2"
                as={Link}
                to="/practice-areas"
                activeClassName="active"
                partiallyActive={true}
              >
                <FontAwesomeIcon icon={faGavel} /> Practice Areas
              </Nav.Link>
              <Nav.Link
                className="my-2"
                as={Link}
                to="/attorneys"
                activeClassName="active"
                partiallyActive={true}
              >
                <FontAwesomeIcon icon={faUserTie} /> Attorneys
              </Nav.Link>
              <Nav.Link
                className="mt-2"
                as={Link}
                to="/about"
                activeClassName="active"
                partiallyActive={true}
              >
                <FontAwesomeIcon icon={faInfoCircle} /> About
              </Nav.Link>
            </section>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <Container fluid className="bg-primary shadow d-none d-sm-block">
      <Container>
        <Navbar fixed className="justify-content-center text-uppercase">
          <Nav.Link
            as={Link}
            to="/practice-areas/criminal-defense"
            activeClassName="active"
            partiallyActive={true}
          >
            Criminal Defense
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/practice-areas/bankruptcy"
            activeClassName="active"
            partiallyActive={true}
          >
            Bankruptcy
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/practice-areas/family-law"
            activeClassName="active"
            partiallyActive={true}
          >
            Family Law
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/practice-areas/personal-injury"
            activeClassName="active"
            partiallyActive={true}
          >
            Personal Injury
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/attorneys"
            activeClassName="active"
            partiallyActive={true}
          >
            Attorneys
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/about"
            activeClassName="active"
            partiallyActive={true}
          >
            About
          </Nav.Link>
        </Navbar>
      </Container>
    </Container>
  </>
)
