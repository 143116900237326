import React from "react"
import { Container } from "react-bootstrap"

const PageHeader = ({ children }) => {
	return <section className="mb-3" style={{
		backgroundImage: "linear-gradient(90deg, rgba(255,255,255,1) 20%, rgba(255,255,255,0)), url('/cover.webp')",
		backgroundSize: "cover",
		backgroundPosition: "center",
	}}>
		<Container className="text-center text-md-left d-flex justify-content-start align-items-center" style={{ minHeight: "40vh" }}>
			{children}
		</Container>
	</section>
};
export default PageHeader;
	